import { Box } from '@chakra-ui/react';
import FirstSec from 'components/Home/FirstSec';
import AboutusSec from 'components/Home/AboutusSec';
import TechnologySec from 'components/Home/TechnologySec';
import FeaturesSec from 'components/Home/FeaturesSec';
import TeamSec from 'components/Home/TeamSec';
import ArticleSec from 'components/Home/ArticleSec';
import HowToBuySec from 'components/Home/HowToBuySec';
import Footer from 'layout/Footer';
import Layout from 'pages/Layout';
import React, { useEffect, useMemo, useState } from 'react';
import AOS from 'aos';

const HomePage: React.FC = () => {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout className='app-page'>
      <>
        <Box className="homepage_main">
          <FirstSec />
          <AboutusSec />
          <TechnologySec />
          <FeaturesSec />
          <TeamSec />
          <ArticleSec />
          <HowToBuySec />
          <Footer />
        </Box>
      </>
    </Layout>
  );
};

export default HomePage;
