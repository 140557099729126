import { Trade, TradeType } from '@uniswap/sdk'
import React, { useContext, useMemo, useState } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'

import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity
} from '../../utils/prices'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds'
import { Box, Button } from '@chakra-ui/react'

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade
  ])
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)

  return (
    <>
      <AutoColumn gap="0px" className="cnfrm_swp_nls ab_cen_bg">
        <RowBetween align="center">
          <Text className="price_txt_nil">
            Price
          </Text>
          <Text
            className="txt_rgt_nill txt_rgt_nill_jg"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              paddingLeft: '10px'
            }}
          >
            {/* 18181.3 upBNB / BNB */}
            {formatExecutionPrice(trade, showInverted)}
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)} className="pp_icn">
              <Repeat size={14} />
              {/* <img src='/img/bi_question-circle-fill.svg' alt="" /> */}
            </StyledBalanceMaxMini>
            {/* <StyledBalanceMaxMini  className="pp_icn">
              <Repeat size={14} />
              <img src='/img/bi_question-circle-fill.svg' alt="" />
            </StyledBalanceMaxMini> */}
          </Text>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <p className="price_txt_nil">
              {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
            </p>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." className='svg_img_jg' />
          </RowFixed>
          <RowFixed>
            {/* <p className="txt_rgt_nill">
              18181.3 upBNB / BNB 17:31
            </p> */}
            <p className="txt_rgt_nill txt_rgt_nill_jg">
              {/* 17.31 upBNB */}
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
              {' '}
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </p>
          </RowFixed>
        </RowBetween>
        <RowBetween className=''>
          <RowFixed>
            <p className="price_txt_nil">
              Price Impact
            </p>
            <QuestionHelper text="The difference between the market price and your price due to trade size." className='svg_img_jg' />
          </RowFixed>
          <Box className='price_01_nil'>
            <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
          </Box>

        </RowBetween>
        <RowBetween>
          <RowFixed>
            <p className="price_txt_nil">
              Liquidity Provider Fee
            </p>
            <QuestionHelper text="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive." className='svg_img_jg' />
          </RowFixed>
          <p className="txt_rgt_nill txt_rgt_nill_jg ">
            {realizedLPFee ? realizedLPFee?.toSignificant(6) + ' ' + trade.inputAmount.currency.symbol : '-'}
          </p>
        </RowBetween>
      </AutoColumn>

      <AutoRow className='btn_cnfrm_swp_nil '>
        <Button
          onClick={onConfirm}
          disabled={disabledConfirm}
          // error={severity > 2}
        >
          {severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
        </Button>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
