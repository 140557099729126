import Fuse from 'fuse.js'
import { useState } from 'react'

interface Props {
  fuse?: any
  data?: any
  term?: any
}

interface Props2 {
  data?: any
  options?: any
}

function fuzzySearch({ fuse, data, term }: Props): any {
  const results = fuse.search(term)
  return term ? results.map((result: { item: any }) => result.item) : data
}

function useFuse({ data, options }: Props2) {
  const [term, setTerm] = useState<string>('')
  const fuseOptions = {
    ...options,
  }
  const fuse = new Fuse(data || [], fuseOptions)
  const result = fuzzySearch({ fuse, data, term })
  const reset = () => setTerm('')
  return {
    result,
    search: setTerm,
    term,
    reset,
  }
}

export default useFuse
