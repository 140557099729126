import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, Heading, Image, Button, Text } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import {
  getEthPrice,
  getTokenPairs2,
  getBulkPairData,
} from '../../utils';
import useFuse from '../../hooks/useFuse'
import useSortableData from '../../hooks/useSortableData'
import { formatNumber } from '../../functions/format';

interface AllPoolProp {
  tokenAddress: string
  isSearchable?: boolean
}

const SortIcon: FC<{ id?: string; direction?: 'ascending' | 'descending'; active: boolean }> = ({
  id,
  active,
  direction,
}) => {
  if (!id || !direction || !active) return <></>
  if (direction === 'ascending') return <Image src="img/up_arrow.svg" alt="" />
  if (direction === 'descending') return <Image src="img/down_arrow.svg" alt="" />
  return <></>

}
export default function AllPool({ tokenAddress, isSearchable = true }: AllPoolProp) {
  const [tokenPairs, updateTokenPairs] = useState<any>(null);

  useEffect(() => {
    async function fetchTokenPairs() {
      const [newPrice] = await getEthPrice();
      const tokenPairs = await getTokenPairs2(tokenAddress);
      const formattedPairs = tokenPairs
        ? tokenPairs.map((pair: any) => {
          return pair.id;
        })
        : [];
      const pairData = await getBulkPairData(formattedPairs, newPrice);
      if (pairData) {
        const pairsFormatted = pairData?.map((pair) => {
          return {
            pair: {
              token0: pair.token0,
              token1: pair.token1,
              id: pair.id,
            },
            liquidity: pair.reserveUSD,
            volume1d: pair.volumeUSD - pair.oneDayVolumeUSD,
            volume1w: pair.volumeUSD - pair.oneWeekVolumeUSD,
          }
        })
        updateTokenPairs(pairsFormatted);
      }
    }
    fetchTokenPairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTokenPairs, tokenAddress]);

  const options = useMemo(
    () => ({
      keys: ['pair.token0.symbol', 'pair.token1.symbol', 'pair.token0.name', 'pair.token1.name'],
      threshold: 0.4,
    }),
    []
  )

  const { result, term, search } = useFuse({
    data: tokenPairs,
    options,
  })

  const { items, requestSort, sortConfig } = useSortableData(result, { key: 'liquidity', direction: 'descending' })

  return (
    <Box className='pool_list_box'>
      <Box className='dashboard_chart_box dashboard_chart_box_last'>
        <Box className='flex_chart_header flex_chart_header_last'>
          <Heading as="h3">List of all pools</Heading>
          {isSearchable ? (
            <Box className='search_bar'>
              <input type="text" placeholder="Type for search" onChange={(e) => search(e.target.value)} value={term} />
              <Button className="serch_btn"><Image src="img/sarch_ic_img.svg" alt="" /> </Button>
            </Box>
          ) : null}
        </Box>
        <Box className='pools_tablemain'>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th onClick={() => requestSort('pair.token0.symbol')}>
                  <Box className='pool_head'>
                    Pool
                    <SortIcon
                      id={sortConfig.key}
                      direction={sortConfig.direction}
                      active={sortConfig.key === 'pair.token0.symbol'} />
                  </Box>
                </Th>
                <Th isNumeric onClick={() => requestSort('liquidity')}>
                  <Box className="tvl_head">
                    TVL
                    <SortIcon
                      id={sortConfig.key}
                      direction={sortConfig.direction}
                      active={sortConfig.key === 'liquidity'} />
                  </Box>
                </Th>
                <Th isNumeric onClick={() => requestSort('volume1d')}>
                  <Box className="volume_head" >
                    24Hr Volume
                    <SortIcon
                      id={sortConfig.key}
                      direction={sortConfig.direction}
                      active={sortConfig.key === 'volume1d'} />
                  </Box>
                </Th>
                <Th isNumeric onClick={() => requestSort('volume1w')}>
                  <Box className="volume_head" >
                    7d Volume
                    <SortIcon
                      id={sortConfig.key}
                      direction={sortConfig.direction}
                      active={sortConfig.key === 'volume1w'} />
                  </Box>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {items?.length > 0 ? (
                <>
                  {items.map((pair, index) => {

                    return (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>
                          <Box className='poolcolm_data'>
                            {/* <Image
                              width={"32px"}
                              height={"32px"}
                              src={`https://assets.trustwalletapp.com/blockchains/smartchain/assets/${isAddress(pair?.token0?.id)}/logo.png`}
                              alt=""
                              fallbackSrc='img/unknown.png'
                            />
                            <Image
                              width={"32px"}
                              height={"32px"}
                              src={`https://assets.trustwalletapp.com/blockchains/smartchain/assets/${isAddress(pair?.token1?.id)}/logo.png`}
                              alt=""
                              fallbackSrc='img/unknown.png'
                            /> */}
                            <Image src="img/table_ic01.svg" alt="" />
                            {pair?.pair?.token0?.symbol}/{pair?.pair?.token1?.symbol}
                            <span>0.3%</span>
                          </Box>
                        </Td>
                        <Td isNumeric>{formatNumber(pair?.liquidity, true, false, 2)}</Td>
                        <Td isNumeric>
                          <Box className="volume_column" >
                            {formatNumber(pair?.volume1d, true, false, 2)}
                          </Box>
                        </Td>
                        <Td isNumeric>{formatNumber(pair?.volume1w, true, false, 2)}</Td>
                      </Tr>
                    )
                  })}
                </>
              ) : null}
            </Tbody>
          </Table>
        </Box>
        <Box className='pagination_row'>
          <Button><Image src='img/Arrow_one.svg' alt="" /></Button>
          <Text>Page <span>1 of 1</span></Text>
          <Button><Image src='img/Arrow_two.svg' alt="" /></Button>
        </Box>
      </Box>
    </Box>
  )
}