import React, { useState, useMemo } from 'react';
// import { Box, Divider } from '@material-ui/core';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  Modal,
  Checkbox,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  useDisclosure,
  FormControl,
  FormLabel,
  Switch
} from '@chakra-ui/react'
import { KeyboardArrowDown } from '@material-ui/icons';
import { AlertTriangle } from 'react-feather';
import {
  CustomModal,
  NumericalInput,
  QuestionHelper,
  ToggleSwitch,
} from 'components';
import { useSwapActionHandlers } from 'state/swap/hooks';
import {
  useExpertModeManager,
  useUserTransactionTTL,
  useUserSlippageTolerance,
} from 'state/user/hooks';
import { ReactComponent as CloseIcon } from 'assets/images/CloseIcon.svg';
import 'components/styles/SettingsModal.scss';
import { useTranslation } from 'react-i18next';

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
}

const SettingsModal2: React.FC<SettingsModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [
    userSlippageTolerance,
    setUserslippageTolerance,
  ] = useUserSlippageTolerance();
  const [ttl, setTtl] = useUserTransactionTTL();
  const { onChangeRecipient } = useSwapActionHandlers();
  const [expertMode, toggleExpertMode] = useExpertModeManager();
  const [slippageInput, setSlippageInput] = useState('');
  const [deadlineInput, setDeadlineInput] = useState('');
  const [expertConfirm, setExpertConfirm] = useState(false);
  const [expertConfirmText, setExpertConfirmText] = useState('');

  const slippageInputIsValid =
    slippageInput === '' ||
    (userSlippageTolerance / 100).toFixed(2) ===
    Number.parseFloat(slippageInput).toFixed(2);
  const deadlineInputIsValid =
    deadlineInput === '' || (ttl / 60).toString() === deadlineInput;

  const slippageError = useMemo(() => {
    if (slippageInput !== '' && !slippageInputIsValid) {
      return SlippageError.InvalidInput;
    } else if (slippageInputIsValid && userSlippageTolerance < 50) {
      return SlippageError.RiskyLow;
    } else if (slippageInputIsValid && userSlippageTolerance > 500) {
      return SlippageError.RiskyHigh;
    } else {
      return undefined;
    }
  }, [slippageInput, userSlippageTolerance, slippageInputIsValid]);

  const slippageAlert =
    !!slippageInput &&
    (slippageError === SlippageError.RiskyLow ||
      slippageError === SlippageError.RiskyHigh);

  const deadlineError = useMemo(() => {
    if (deadlineInput !== '' && !deadlineInputIsValid) {
      return DeadlineError.InvalidInput;
    } else {
      return undefined;
    }
  }, [deadlineInput, deadlineInputIsValid]);

  const parseCustomSlippage = (value: string) => {
    setSlippageInput(value);

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt(
        (Number.parseFloat(value) * 100).toString(),
      );
      if (
        !Number.isNaN(valueAsIntFromRoundedFloat) &&
        valueAsIntFromRoundedFloat < 5000
      ) {
        setUserslippageTolerance(valueAsIntFromRoundedFloat);
      }
    } catch { }
  };

  const parseCustomDeadline = (value: string) => {
    setDeadlineInput(value);

    try {
      const valueAsInt: number = Number.parseInt(value) * 60;
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setTtl(valueAsInt);
      }
    } catch { }
  };

  return (
    <Box className='modale_box'>
      <Modal isOpen={open} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent className='man_box_modal'>
          <ModalHeader className='trans_hader'>Transaction Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box className='modal_body'>
              <Heading as="h2">Slippage tolerance</Heading>
              <Box className='forth_box_nums'>
                <Box className='cstm_radio'>
                  <input type='radio' name='tollrens'
                    checked={userSlippageTolerance == 10}
                    onClick={() => {
                      setSlippageInput('');
                      setUserslippageTolerance(10);
                    }} />
                  <Heading as='h6' className='point_smn_won'>
                    0.1%
                  </Heading>
                </Box>
                <Box className='cstm_radio'>
                  <input type='radio' name='tollrens'
                    checked={userSlippageTolerance == 50}
                    onClick={() => {
                      setSlippageInput('');
                      setUserslippageTolerance(50);
                    }} />
                  <Heading as='h6' className='point_smn_won point_smn_nonas'>
                    0.5%
                  </Heading>
                </Box>
                <Box className='cstm_radio'>
                  <input type='radio' name='tollrens'
                    checked={userSlippageTolerance == 100}
                    onClick={() => {
                      setSlippageInput('');
                      setUserslippageTolerance(100);
                    }} />
                  <Heading as='h6' className='point_smn_won'>
                    1%
                  </Heading>
                </Box>
                <Box className='input_any_popup'>
                  <NumericalInput
                    type='text'
                    placeholder={(userSlippageTolerance / 100).toFixed(2)}
                    value={slippageInput}
                    className='nums_color'
                    onBlur={() => {
                      parseCustomSlippage((userSlippageTolerance / 100).toFixed(2));
                    }}
                    onUserInput={(value) => {
                      parseCustomSlippage(value)
                    }} />
                </Box>
              </Box>
              <Heading as="h4">Transaction deadline</Heading>
              <Box className='mint_box'>
                <NumericalInput
                  type='text'
                  className='nums_color'
                  placeholder={(ttl / 60).toString()}
                  value={deadlineInput}
                  onBlur={() => {
                    parseCustomDeadline((ttl / 60).toString());
                  }}
                  onUserInput={(value) => parseCustomDeadline(value)}
                />
                <Text>Min</Text>
              </Box>
              <Heading as="h1">Transaction deadline</Heading>
              <Box className='switch_box margi_btm'>
                <Text>Toggle Expert Mode</Text>
                <FormControl display='flex' alignItems='center' className='switch_min'>
                  <FormLabel className='switch_text'>
                    on
                  </FormLabel>
                  <Switch id='email-alerts' className='round_switch' />
                  <FormLabel className='switch_text'>
                    off
                  </FormLabel>
                </FormControl>
              </Box>
              <Box className='switch_box'>
                <Text>Disable Multihops</Text>
                <FormControl display='flex' alignItems='center' className='switch_min'>
                  <FormLabel className='switch_text'>
                    on
                  </FormLabel>
                  <Switch id='email-alerts' className='round_switch' />
                  <FormLabel className='switch_text'>
                    off
                  </FormLabel>
                </FormControl>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SettingsModal2;
