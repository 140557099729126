
import { Box, Button } from '@material-ui/core';
import { DoubleCurrencyLogo } from 'components';
import useUSDCPrice from 'utils/useUSDCPrice';
import { ReactComponent as ArrowDownIcon } from 'assets/images/ArrowDownIcon.svg';
import { formatTokenAmount } from 'utils';
import { useTranslation } from 'react-i18next';
import { Trade, TradeType } from '@uniswap/sdk'

import React, { useContext, useMemo } from 'react'
import { ArrowDown, AlertTriangle } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { ButtonPrimary } from '../Button'
import { isAddress, shortenAddress } from '../../utils'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'
import { color, Image } from '@chakra-ui/react';
import { GlobalConst } from '../../constants';

interface SwapModalHeaderProps {
  trade: Trade;
  allowedSlippage: number;
  showAcceptChanges: boolean;
  onAcceptChanges: () => void;
  onConfirm: () => void;
}

const SwapModalHeader: React.FC<SwapModalHeaderProps> = ({
  trade,
  allowedSlippage,
  showAcceptChanges,
  onAcceptChanges,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage],
  );
  // const usdPrice = useUSDCPrice(trade.inputAmount.currency);
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)
  const theme = useContext(ThemeContext)
  return (
    <>
      {/* <Box>
        <Box className='ab_cen_bg'>
          <Box mt={10} className='flex justify-center ab_mar_30'>
            <DoubleCurrencyLogo
              currency0={trade.inputAmount.currency}
              currency1={trade.outputAmount.currency}
              size={48}
            />
          </Box>
          <Box className='swapContent'>
            <p className='ab_cent_p'>
              {t('swap')} {formatTokenAmount(trade.inputAmount)}{' '}
              {trade.inputAmount.currency.symbol} ($
              {Number(usdPrice?.toSignificant()) *
                Number(trade.inputAmount.toSignificant(2))}
              )
            </p>
            <ArrowDownIcon />
            <p>
              {formatTokenAmount(trade.outputAmount)}{' '}
              {trade.outputAmount.currency.symbol}
            </p>
          </Box>
          {showAcceptChanges && (
            <Box className='priceUpdate'>
              <Box>
                <AlertTriangle size={20} />
                <p>{t('priceUpdated')}</p>
              </Box>
              <Button onClick={onAcceptChanges}>{t('accept')}</Button>
            </Box>
          )}
          <Box className='transactionText'>
            {trade.tradeType === TradeType.EXACT_INPUT ? (
              <p className='small'>
                {t('outputEstimated1', {
                  amount: formatTokenAmount(slippageAdjustedAmounts[Field.OUTPUT]),
                  symbol: trade.outputAmount.currency.symbol,
                })}
              </p>
            ) : (
              <p className='small'>
                {t('inputEstimated', {
                  amount: formatTokenAmount(slippageAdjustedAmounts[Field.INPUT]),
                  symbol: trade.inputAmount.currency.symbol,
                })}
              </p>
            )}
            <Button onClick={onConfirm} className='swapButton ab_swap_btn'>
              {t('Confirm Swap')}
            </Button>
          </Box>
        </Box>
        <Button onClick={onConfirm} className='swapButton ab_swap_btn'>
          {t('Confirm Swap')}
        </Button>
      </Box> */}
      <AutoColumn gap={'md'} className="cnfrm_swp_nls ab_cen_bg">
        <RowBetween align="flex-end">
          <RowFixed gap={'0px'}>
            {trade.inputAmount.currency.symbol == GlobalConst.utils.ROOTED_TICKER ? (
              <Image
                src='/img/upcro_coin_ic.svg'
                alt=''
                className='upcro_coin_ic'
              />
            ) : (
              <CurrencyLogo currency={trade.inputAmount.currency} size={'24px'} style={{ marginRight: '12px' }} />
            )}
            {/* <CurrencyLogo currency={trade.inputAmount.currency} size={'24px'} style={{ marginRight: '12px' }} /> */}
            <TruncatedText
              className='text_box'
              fontSize={22}
              fontWeight={500}
            >
              {trade.inputAmount.toSignificant(6)}
            </TruncatedText>
          </RowFixed>
          <RowFixed gap={'0px'} className='black_box_jg'>
            <Text fontSize={17} fontWeight={500} className="emire_txt_nll" >
              {trade.inputAmount.currency.name}
            </Text>
            <Text fontSize={17} fontWeight={500} className="emire_txt_nll_jn" >
              {trade.inputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed className='aroy_jg'>
          <ArrowDown size="16" style={{ marginLeft: '4px', minWidth: '16px' }} />
        </RowFixed>
        <RowBetween align="flex-end">
          <RowFixed gap={'0px'}>
            {trade.outputAmount.currency.symbol == GlobalConst.utils.ROOTED_TICKER ? (
              <Image
                src='/img/upcro_coin_ic.svg'
                alt=''
                className='upcro_coin_ic'
              />
            ) : (
              <CurrencyLogo currency={trade.outputAmount.currency} size={'24px'} style={{ marginRight: '12px' }} />
            )}
            {/* <CurrencyLogo currency={trade.outputAmount.currency} size={'24px'} style={{ marginRight: '12px' }} /> */}
            <TruncatedText
              className='text_box'
              fontSize={22}
              fontWeight={500}
            >
              {trade.outputAmount.toSignificant(6)}
            </TruncatedText>
          </RowFixed>
          <RowFixed gap={'0px'} className='black_box_jg black_box_jg_v2'>
            <Text fontSize={17} fontWeight={500} style={{ marginLeft: '0px' }} className="adafe_txt_nll emire_txt_nll" >
              {trade.outputAmount.currency.name}
            </Text>
            <Text fontSize={17} fontWeight={500} className="emire_txt_nll_jn" >
              {trade.outputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        {showAcceptChanges ? (
          <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
            <RowBetween>
              <RowFixed>
                <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
                <p> Price Updated</p>
              </RowFixed>
              <ButtonPrimary
                style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
                onClick={onAcceptChanges}
              >
                Accept
              </ButtonPrimary>
            </RowBetween>
          </SwapShowAcceptChanges>
        ) : null}
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          {trade.tradeType === TradeType.EXACT_INPUT ? (
            <p style={{ width: '100%' }} className="output_txt_nls">
              {`Output is estimated. You will receive at least `}
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)} {trade.outputAmount.currency.symbol}
              {' or the transaction will revert.'}
            </p>
          ) : (
            <p style={{ width: '100%' }}>
              {`Input is estimated. You will sell at most `}
              <b>
                {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)} {trade.inputAmount.currency.symbol}
              </b>
              {' or the transaction will revert.'}
            </p>
          )}
        </AutoColumn>
      </AutoColumn>
    </>
  );
};

export default SwapModalHeader;
