import { Box, Text } from '@chakra-ui/react';
import { ChainId, Token, Trade } from '@uniswap/sdk';
import React, { Component, useState } from 'react'
import useUSDCPrice from 'utils/useUSDCPrice';
import { ReactComponent as PriceExchangeIcon } from 'assets/images/PriceExchangeIcon.svg';
import { Currency } from '@uniswap/sdk-core';
import { Field } from 'state/swap/actions';

const SwapPrice: React.FC<{
  trade: Trade,
  currencies: any
}> = ({ trade, currencies }) => {
  const [mainPrice, setMainPrice] = useState(true);

  const inputCurrency = trade?.inputAmount.currency;
  // console.log("test-fee-inputCurrency", inputCurrency);
  
  const gasFee = Number(useUSDCPrice(inputCurrency)?.toSignificant() ?? 0);
  // console.log("test-fee-gasFee", gasFee);

  return (
    <>
      <Box className='text_row_darc'>
        <Text>
          1{' '}
          {
            (mainPrice ? currencies[Field.INPUT] : currencies[Field.OUTPUT])
              ?.symbol
          }{' '}
          ={' '}
          {(mainPrice
            ? trade.executionPrice
            : trade.executionPrice.invert()
          ).toSignificant(6)}{' '}
          {
            (mainPrice ? currencies[Field.OUTPUT] : currencies[Field.INPUT])
              ?.symbol
          }{' '}
        </Text>
        {/* <Text>($0.00065486635)</Text>
        <Text>Gas: $32.455</Text> */}
        <PriceExchangeIcon
          onClick={() => {
            setMainPrice(!mainPrice);
          }}
        />
      </Box>
    </>
  )
}

export default SwapPrice;
