import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
  Link,
  Button,
} from '@chakra-ui/react';
import {
  FiMenu,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { NavLink } from 'react-router-dom';
import Web3Modal from "web3modal";
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { toHex, shortenAddress } from "../utils";

const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Web3", // Required
      infuraId: process.env.INFURA_KEY // Required unless you provide a JSON RPC url; see `rpc` below
    }
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: process.env.INFURA_KEY // required
    }
  }
};

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});

// interface LinkItemProps {
//   name: string;
//   icon: IconType;
// }
// const LinkItems: Array<LinkItemProps> = [
//   { name: 'Home', icon: FiHome },
//   { name: 'Trending', icon: FiTrendingUp },
//   { name: 'Explore', icon: FiCompass },
//   { name: 'Favourites', icon: FiStar },
//   { name: 'Settings', icon: FiSettings },
// ];

const LayoutTwo = (props: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <Box minH="100vh">
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', xl: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent className='drawer_slider'>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen}  />
        <Box ml={{ base: 0, xl: "300px" }} className="sidebar_main_prnt">
          {props.children}
        </Box>
      </Box>
    );
  }
  
  interface SidebarProps extends BoxProps {
    onClose: () => void;
  }
  
  const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');
    return (
      <Box
        transition="0.3s ease"
        w={{ base: 'full', xl: "300px" }}
        pos="fixed"
        zIndex={999999}
        h="full"
        {...rest}
        className="sidebar_main"
        >
        <Flex  alignItems="center" className='sidebar_logo_prnt'>
          <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
            <NavLink to="/">
              <Image src="img/dahboard_logo.png" alt="" />
            </NavLink>
          </Text>
          <CloseButton display={{ base: 'flex', xl: 'none' }} onClick={onClose} className="sidebar_close_btn" />
        </Flex>
        <Flex className='dashboard_links_prnt'>
          <Text>App</Text>
          <Flex className='dashboard_links'>
            <NavLink
              to='/dashboard'
              className={splitLocation[1] === 'dashboard' ? 'active' : ''}
            >
              <Image src='img/sidebar_link_ic01.svg' alt="" />
              <span>Dashboard</span>&nbsp;<span style={{fontSize:"12px", color:"#FCD882", height:"20px"}}>Coming Soon</span>
            </NavLink>
            <NavLink
              to='/staking'
              className={splitLocation[1] === 'stake' ? 'active' : ''}
            >
              <Image src='img/sidebar_link_ic02.svg' alt="" />
              <span>Stake</span>
            </NavLink>
            <NavLink
              to='/swap'
              className={splitLocation[1] === 'swap' ? 'active' : ''}
            >
              <Image src='img/sidebar_link_ic03.svg' alt="" />
              <span>Swap</span>
            </NavLink>
            <NavLink
              // to='#'
              // className={''}
              to='/pools'
              className={splitLocation[1] === '' ? 'active' : ''}
            >
              <Image src='img/sidebar_link_ic04.svg' alt="" />
              <span>Pools</span>&nbsp;<span style={{fontSize:"12px", color:"#FCD882", height:"20px"}}>Coming Soon</span>
            </NavLink>
            {/* <NavLink
              to='/'
              className={splitLocation[1] === '' ? 'active' : ''}
            >
              <Image src='img/sidebar_link_ic071.svg' alt="" />
              <span>Votingddhjdhhdh</span>
            </NavLink> */}
            <NavLink
              // to='#'
              // className={''}
              to='/faq'
              className={splitLocation[1] === '' ? 'active' : ''}
            >
              <Image src='img/sidebar_link_ic06.svg' alt="" />
              <span>FAQ</span>&nbsp;<span style={{fontSize:"12px", color:"#FCD882", height:"20px"}}>Coming Soon</span>
            </NavLink>
            {/* <NavLink
              to='/roadmap'
              className={splitLocation[1] === '' ? 'active' : ''}
            >
              <Image src='img/sidebar_link_ic06.svg' alt="" />
              <span>Roadmap</span>
            </NavLink> */}
          </Flex>
        </Flex>
        <Flex className='social_links_prnt'>
          <Text>Social Media</Text>
          <Flex className='socl_links_dash'>
            <Link href='#'><Image src='img/twitter_ic.svg' alt="" /><span>Twitter</span></Link>
            <Link href='#'><Image src='img/telegram_ic.svg' alt="" /><span>Telegram</span></Link>
            <Link href='#'><Image src='img/discord_ic.svg' alt="" /><span>Discord</span></Link>
            <Link href='#'><Image src='img/medium_ic.svg' alt="" /><span>Medium</span></Link>
          </Flex>
        </Flex>
        {/* {LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon}>
            {link.name}
          </NavItem>
        ))} */}
      </Box>
    );
  };
  
  interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
  }
  const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
    return (
      <Link href="#" style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            // bg: 'cyan.400',
            color: 'white',
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    );
  };
  
  interface MobileProps extends FlexProps {
    onOpen: () => void;
  }
  const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    const [scroll, setScroll] = useState(false)
    const { account, activate, deactivate } = useWeb3React();
    const injectedProvider = new InjectedConnector({
      supportedChainIds: [137],
    });
    // mainnet only
    const walletconnect = new WalletConnectConnector({
      rpc: {
        1: "https://polygon-rpc.com/",
      }, // Infura URL does not work 
      bridge: 'https://bridge.walletconnect.org',
      qrcode: true,
      // pollingInterval: 15000,
      supportedChainIds: [137],
      chainId: 137
    })
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10)
      })
  
    }, [])
  
    const connectWallet = async () => {
      // console.log("LayoutTwo connectWallet")
      if (account) {
        // console.log("LayoutTwo connectWallet-account")
        try {
          web3Modal.clearCachedProvider()
          deactivate();
          localStorage.setItem('WALLET_PROVIDER', '');
        } catch (e) {
          console.log("LayoutTwo connectWallet-account error", e);
        }
        return;
      }
      else {
        // console.log("LayoutTwo connectWallet-no account")
        const provider = await web3Modal.connect();
        try {
          if (provider.isMetaMask) {
            // console.log("LayoutTwo connectWallet-is MetaMask")
            await provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: toHex("137") }]
            });
  
            await activate(injectedProvider);
            localStorage.setItem('WALLET_PROVIDER', 'injected');
          } else {
            // console.log("LayoutTwo connectWallet-is no MetaMask")
            await activate(walletconnect);
            localStorage.setItem('WALLET_PROVIDER', 'walletconnect');
          }
        } catch (e) {
          // console.log("LayoutTwo connectWallet-active error", e);
        }
      }
    }
    
    useEffect(() => {
      const connect = async () => {
        const providerType = localStorage.getItem(
          'WALLET_PROVIDER'
        );
        if (providerType === 'injected' || providerType === 'walletconnect') {
          const provider = await web3Modal.connectTo(providerType);
          try {
            if (provider.isMetaMask) {
              // await provider.request({
              //   method: "wallet_switchEthereumChain",
              //   params: [{ chainId: toHex("1") }]
              // });
  
              await activate(injectedProvider);
              localStorage.setItem('WALLET_PROVIDER', 'injected');
            } else {
              await activate(walletconnect);
              localStorage.setItem('WALLET_PROVIDER', 'walletconnect');
            }
          } catch (e) {
            console.log('active error', e);
          }
        }
      }
      connect()
    }, []);
    return (
      <>
      <Box className="margin_a">
        <Flex
          ml={{ base: 0, xl: 60 }}
          alignItems="center"
          justifyContent={{ base: 'space-between', xl: 'flex-end' }}
          className={scroll ? "sidebar_header scrolled" : "sidebar_header"}
          {...rest}>
          <IconButton
            display={{ base: 'flex', xl: 'none' }}
            onClick={onOpen}
            variant="outline"
            aria-label="open menu"
            className="dashbourd_menu_btn"
            icon={<FiMenu />}
          />
          <HStack spacing={{ base: '0', xl: '6' }} >
            <Flex alignItems={'center'} className="sidebar_header_btns">
              <Button as="a" href="#" >Contract Address List</Button>
              <Button as="a" href="#" className='cnct_wallet_btn' onClick={connectWallet}>
                {!account ? 'Connect Wallet' : shortenAddress(account)}
              </Button>
            </Flex>
          </HStack>
        </Flex>
      </Box>
      
      </>
  );
};

export default LayoutTwo;
