import React, { useCallback, useState } from 'react';
import { Currency } from '@uniswap/sdk';
import { Box } from '@material-ui/core';
import { useCurrencyBalance } from 'state/wallet/hooks';
import { CurrencySearchModal, CurrencyLogo, NumericalInput } from 'components';
import { useActiveWeb3React } from 'hooks';
import useUSDCPrice from 'utils/useUSDCPrice';
import { formatTokenAmount } from 'utils';
import 'components/styles/CurrencyInput.scss';
import { useTranslation } from 'react-i18next';
import { Button, Image, Text } from '@chakra-ui/react';
import { GlobalConst } from 'constants/index';

interface CurrencyInputProps {
  title?: string;
  handleCurrencySelect: (currency: Currency) => void;
  currency: Currency | undefined;
  otherCurrency?: Currency | undefined;
  amount: string;
  setAmount: (value: string) => void;
  onMax?: () => void;
  onHalf?: () => void;
  showHalfButton?: boolean;
  showMaxButton?: boolean;
  showPrice?: boolean;
  bgClass?: string;
  id?: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  handleCurrencySelect,
  currency,
  otherCurrency,
  amount,
  setAmount,
  onMax,
  onHalf,
  showMaxButton,
  showHalfButton,
  title,
  showPrice,
  bgClass,
  id,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { account } = useActiveWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency,
  );
  const usdPrice = Number(useUSDCPrice(currency)?.toSignificant() ?? 0);

  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  return (
    <>
      <Box className='swap_upcro_brdr_bx'>
        <Box className='flex_bx'>
          {/* <input type="number" value="281.594" /> */}
          <NumericalInput
            value={amount}
            placeholder='0.00'
            onUserInput={(val) => {
              setAmount(val);
            }}
          />
          <Button
            className='upmatic_btn_pop'
          // onClick={handleOpenModal}
          >
            {/* <Image
              src='/img/upcro_coin_ic.svg'
              alt=''
              className='upcro_coin_ic'
            />
            upMATIC */}
            {currency ? (
              <>
                {currency.symbol == GlobalConst.utils.ROOTED_TICKER ? (
                  <Image
                    src='/img/upcro_coin_ic.svg'
                    alt=''
                    className='upcro_coin_ic'
                  />
                ) : (
                  <CurrencyLogo currency={currency} size={'28px'} />
                )}
                {' '}{currency?.symbol}
              </>
            ) : "Select Token"}
            <Image
              src='/img/pop_01.svg'
              alt=''
              className='down_arrow'
            />
          </Button>
        </Box>

        <Box className='flex_bx flex_bx_02'>
          <Text>${(usdPrice * Number(amount)).toLocaleString()}</Text>
          <Text>Balance: {formatTokenAmount(selectedCurrencyBalance)}</Text>
        </Box>
        {modalOpen && (
          <CurrencySearchModal
            isOpen={modalOpen}
            onDismiss={() => {
              setModalOpen(false);
            }}
            onCurrencySelect={handleCurrencySelect}
            selectedCurrency={currency}
            showCommonBases={true}
            otherSelectedCurrency={otherCurrency}
          />
        )}
      </Box>
    </>
  );
};

export default CurrencyInput;
