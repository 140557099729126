import React from 'react';
import './assets/css/App.scss';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import { ChakraProvider } from '@chakra-ui/react';
import { Web3Provider } from '@ethersproject/providers';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import store from 'state';
import { GlobalConst } from 'constants/index';
import ApplicationUpdater from 'state/application/updater';
import TransactionUpdater from 'state/transactions/updater';
import ListsUpdater from 'state/lists/updater';
import UserUpdater from 'state/user/updater';
import MulticallUpdater from 'state/multicall/updater';
import FarmUpdater from 'state/farms/updater';
import DualFarmUpdater from 'state/dualfarms/updater';
import SyrupUpdater from 'state/syrups/updater';


const Web3ProviderNetwork = createWeb3ReactRoot(
  GlobalConst.utils.NetworkContextName,
);

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
      <ListsUpdater />
      <MulticallUpdater />
      <UserUpdater />
      <FarmUpdater />
      <DualFarmUpdater />
      <SyrupUpdater />
    </>
  );
}

function App() {
  const getLibrary = (provider: any) => {
    const library = new Web3Provider(provider, 'any');
    library.pollingInterval = 15000;
    return library;
  };
  return (
    <>
      <ChakraProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Provider store={store}>
              <Updaters />
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </Provider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
